<script setup>
import { WdsPillFloating, WdsPropertyCard, WdsTag } from '@wds/components';

const props = defineProps({
  properties: {
    type: Object,
    required: true,
    default: () => ({}),
  },
  source: {
    type: String,
    default: () => '',
  },
  campaignType: {
    type: String,
    default: () => '',
  },
});

const { useUrl } = useUrls();
const { useImage } = useImages();
const { $tracking } = useNuxtApp();
const { useRating } = useRatings();

// REF
const galleryList = ref(null);

// ### METHODS
function scrollRight() {
  const galleryListRef = toValue(galleryList);
  galleryListRef?.scrollBy({
    left: 276,
    top: 0,
    behavior: 'smooth',
  });
}

function scrollLeft() {
  const galleryListRef = toValue(galleryList);
  galleryListRef?.scrollBy({
    left: -276,
    top: 0,
    behavior: 'smooth',
  });
}

function getPropertyDetailsForCard(property) {
  const propertyImage = property?.image || null;
  const propSlides = propertyImage ? [useImage.getImage(propertyImage)] : [];
  const propertyUrl = `${useUrl.getPwaLangBaseUrl()}pwa/wds/hosteldetails.php/${property?.urlFriendlyName}/${property?.location?.city?.urlFriendlyName}/${property?.propertyId}/`;
  return {
    name: property?.title,
    href: propertyUrl || property?.link,
    distance: property?.subtitle,
    gallery: {
      slides: propSlides,
      alt: property?.title,
      title: property?.title,
    },
    rating: {
      score: property?.score,
      totalReviews: property?.reviews,
      translations: toValue(useRating.getRatingScoreKeywordTranslations),
    },
  };
}
function onPropertyCardClick(property, index) {
  if (props.campaignType === 'seasonalDeals') {
    $tracking?.onSeasonalDealClick(property, index);
  } else {
    $tracking?.onBFLPDealClick(property, index, props.source);
  }
}
</script>

<template>
  <div
    v-if="properties?.length"
    class="cards-container"
  >
    <div class="nav">
      <div class="deals-navigation">
        <WdsPillFloating
          icon-start="arrow-left"
          aria-label="left arrow"
          @click="scrollLeft"
        />
        <WdsPillFloating
          icon-start="arrow-right"
          aria-label="right arrow"
          @click="scrollRight"
        />
      </div>
    </div>

    <div class="popular-deals-wrapper">
      <div
        ref="galleryList"
        class="popular-deals-card-list"
      >
        <WdsPropertyCard
          v-for="(property, index) in properties"
          :key="property.propertyId"
          :show-pricing="false"
          v-bind="getPropertyDetailsForCard(property)"
          class="deal-card"
          @click="onPropertyCardClick(property, index)"
        >
          <template #custom-tags>
            <WdsTag
              class="deal-tag"
              :text="property.tag"
              color="pink"
            />
          </template>
        </WdsPropertyCard>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cards-container {
  @include tablet {
    margin-top: wds-rem(-32px);
  }

  .nav {
    display: grid;
    grid-template-columns: 1fr;

    .deals-navigation {
      display: none;
      align-items: center;
      justify-content: flex-end;
      gap: $wds-spacing-m;
    }

    @include tablet {
      .deals-navigation {
        display: flex;
      }
    }
  }

  h1 {
    @include title-3-bld;

    margin-bottom: $wds-spacing-m;

    @include tablet {
      @include title-2-bld;
    }
  }

  .popular-deals-wrapper {
    overflow: hidden;
    width: 100%;

    .popular-deals-card-list {
      display: inline-flex;
      gap: $wds-spacing-m;
      overflow-x: auto;
      scroll-behavior: smooth;
      scroll-snap-type: x proximity;
      padding: $wds-spacing-m 0;
      width: 100%;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      .deal-card {
        min-width: wds-rem(260px);
      }

      .deal-tag {
        :deep(.tag-text) {
          @include title-6-bld;
        }
      }

      > * {
        flex: 0 0 wds-rem(260px);
        height: auto;
      }
    }
  }
}
</style>
